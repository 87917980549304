import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

import HeaderText from "./HeaderText";

import Emblem from "../../Assets/emblem-logo.png";
import Wells from "../../Assets/wells-logo.png";
import Wells_PBL from "../../Docs/Wells_PBL.pdf";
import Emblem_letter from "../../Docs/Emblem_letter.pdf";

const PAScreenWrapper = styled.div`
  padding: 16px;
`;

const PAScreenBack = styled.button`
  padding: 0;
  margin-bottom: 16px;
  background-color: transparent;
  display: flex;
`;

const FakePA = styled.div`
  text-align: center;
  padding: 24px 0;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const EmblemLogo = styled.img`
  width: 60%;
  margin-bottom: 4px;
`;

const PurchasePrice = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`;

const Price = styled.h1`
  font-size: 32px;
  margin-top: 4px;
`;

const SliderContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 100px;
  background: #dce1e6;
  outline: none;
`;

const PAButton = styled.a`
  display: block;
  width: 100%;
  background-color: #66c3cc;
  padding: 16px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 500px;
  margin-top: 48px;
`;

class PAScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      amount: "$500,000",
      started: false
    };
  }

  closeOut = () => {
    console.log("closing out of Pa");
    this.props.onClose();
  };

  newAmount = e => {
    let amount = e.target.value;
    let rounded = Math.round(amount / 5000) * 5000;
    let formatted = "$" + rounded.toLocaleString();
    this.setState({
      amount: formatted,
      started: true
    });
  };

  render() {
    return (
      <PAScreenWrapper className="pa-screen">
        <PAScreenBack onClick={this.closeOut}>
          <MaterialIcon icon="arrow_back" color="#143859" />
        </PAScreenBack>
        <HeaderText
          h1={"Adjustable pre-approval"}
          subtext={"You can generate new pre-approval letters up to $500,000."}
        />
        <FakePA>
          <EmblemLogo
            src={this.props.lender == "Wells Fargo" ? Wells : Emblem}
          />

          <PurchasePrice>
            <h5>Purchase price</h5>
            <Price>{this.state.amount}</Price>
          </PurchasePrice>
        </FakePA>
        <SliderContainer>
          <Slider
            className="slider"
            type="range"
            min="200000"
            max="500000"
            value={this.state.started ? null : 500000}
            onChange={this.newAmount}
          />
        </SliderContainer>

        <PAButton
          href={this.props.lender == "Wells Fargo" ? Wells_PBL : Emblem_letter}
          target="_blank"
        >
          Generate pre-approval (PDF)
        </PAButton>
      </PAScreenWrapper>
    );
  }
}

PAScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  lender: PropTypes.string.isRequired
};

export default PAScreen;
