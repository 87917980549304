import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Landing from "./Landing";
import Home from "./Home";
import Pipeline from "./Pipeline";
import LeadBuyer from "./LeadBuyer";
import LOSearch from "./LOSearch";
import LeadReview from "./LeadReview";
import BuyerActivity from "./BuyerActivity";
import PreApproval from "./PreApproval";

const StyledTask = styled.div`
  width: 100%;
  // padding: 0px 16px;
`;

let newBuyer = {};
let newID = 1;
let statusID = 1;

let lead = {
  buyer: {
    firstName: "Bailey",
    lastName: "Buyer",
    phone: "(800) - 123 - 4567",
    email: "baileybuyer@gmail.com"
  },
  loanOfficer: {
    firstName: "Anthony",
    lastName: "Porter",
    lender: "Emblem Bank",
    phone: "(800) - 555 - 5555",
    email: "anthonyporter@emblem.com"
  }
};
class Task extends React.Component {
  constructor() {
    super();
    this.state = {
      clients: [],
      selected: null,
      customer: "Emblem Bank",
      // customer: "Wells Fargo",
      onLanding: true
    };
  }

  componentWillMount() {
    this.handlePhoneWrapper(false);
  }

  handlePhoneWrapper = isLanding => {
    this.setState({ onLanding: isLanding });
    console.log("setting to " + isLanding);
  };

  pullUp = buyer => {
    // add this buyer to our array of Buyers
    newBuyer = buyer;
  };

  chooseLO = officer => {
    // call finalize lead at this point
    this.finalizeLead(newBuyer, officer);
  };

  finalizeLead = (buyer, officer) => {
    if (officer) {
      lead = {
        buyer: buyer,
        loanOfficer: officer
      };
    }
    // lead is passed as prop to the review screen
  };

  createBuyer = lead => {
    console.log(lead);
    // want to bubble this up to App.js
    // or maybe not
    let buyerX = {
      buyerID: newID,
      buyerInfo: {
        firstName: lead.buyer.firstName,
        lastName: lead.buyer.lastName
      },
      lenderInfo: {
        firstName: lead.loanOfficer.firstName,
        lastName: lead.loanOfficer.lastName,
        lender: this.state.customer
      },
      lead: true,
      leadStatus: `Waiting on ${
        lead.buyer.firstName
      } to start their mortgage application`,
      statuses: [
        {
          statusID: 1,
          type: "status",
          statusText: `You created ${lead.buyer.firstName} as a lead`,
          statusTime: "Just now"
        },
        {
          statusID: 2,
          type: "status",
          statusText: `You invited ${lead.buyer.firstName} to fill out ${
            lead.loanOfficer.firstName
          }'s application`,
          statusTime: "Just now"
        }
      ]
    };
    this.setState({
      clients: [...this.state.clients, buyerX]
    });
    newID += 1;
  };

  chooseBuyer = buyer => {
    // now the BA page should have no problem whatsoever; literally just pass in selected
    this.setState({
      selected: buyer
    });
  };

  // whenever a buyer is modified, pull up the state
  // hmm actually, im going ot make the PA screen a modal for now...

  // if on landing url, then don't show phone-wrapper
  // or maybe add phone-wrapper className on click of button

  // hmm maybe do withRouter(Landing) and then pass that back up ??
  // manage phone wrapper with state

  // dont even need URL; just when landing willUnmount, change state to view phone wrapper

  render() {
    return (
      <StyledTask>
        <Router>
          {/* {this.state.onLanding && ( */}
          <div className="landing-wrapper">
            <Route
              exact
              path={"/landing"}
              render={props => (
                <Landing
                  {...props}
                  handlePhoneWrapper={this.handlePhoneWrapper}
                />
              )}
            />
          </div>
          {/* )} */}

          {!this.state.onLanding && (
            <div className="phone-wrapper">
              <Route exact path={"/demo/home"} component={Home} />
              <Route
                path={"/demo/pipeline"}
                render={props => (
                  <Pipeline
                    {...props}
                    state={this.state.clients}
                    onChooseBuyer={this.chooseBuyer}
                  />
                )}
              />
              <Route
                path={"/demo/leadbuyer"}
                render={props => (
                  <LeadBuyer {...props} onLeadSubmit={this.pullUp} />
                )}
              />
              <Route
                path={"/demo/losearch"}
                render={props => (
                  <LOSearch
                    {...props}
                    onChooseLO={this.chooseLO}
                    lender={this.state.customer}
                  />
                )}
              />
              <Route
                path={"/demo/review"}
                render={props => (
                  <LeadReview
                    {...props}
                    ingestLead={lead}
                    bubbleLead={this.createBuyer}
                    lender={this.state.customer}
                  />
                )}
              />
              <Route
                path={"/demo/buyeractivity"}
                render={props => (
                  <BuyerActivity
                    {...props}
                    state={this.state.selected}
                    lender={this.state.customer}
                  />
                )}
              />
              <Route exact path={"/demo/preapproval"} component={PreApproval} />
            </div>
          )}
        </Router>
      </StyledTask>
    );
  }
}

Task.propTypes = {
  // actually i dont think task has any props
  // should i pass it to the route ??
};

export default Task;
