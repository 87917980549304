import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import MaterialIcon from "material-icons-react";
import styled from "styled-components";

const StyledBack = styled.button`
  padding: 0;
  margin: 16px 0;
  background-color: transparent;
  display: flex;
`;

class BackButton extends Component {
  constructor() {
    super();
    // create a ref to handle disabled state
    this.AdvanceRef = React.createRef();
  }

  render() {
    return (
      <StyledBack
        ref={this.AdvanceRef}
        disabled={this.props.disabled}
        onClick={() => {
          this.props.history.goBack();
        }}
      >
        <MaterialIcon icon="arrow_back" color="#5B748B" />
      </StyledBack>
    );
  }
}

// proptypes
BackButton.propTypes = {
  disabled: PropTypes.bool
};

// this is how to access router props (important)
export default withRouter(BackButton);
