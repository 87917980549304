import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import posed from "react-pose";

import HeaderText from "../UI/HeaderText";
import Input from "../UI/Input";
import BackButton from "../UI/BackButton";
import LORow from "../UI/LORow";

import Tones from "../../Assets/tones.png";
import Lawder from "../../Assets/lawder.png";

const poseProps = {
  hidden: {
    opacity: 0,
    x: 40
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      opacity: { ease: "easeOut", duration: 500 },
      x: { ease: "easeOut", duration: 300, delay: 50 }
    }
  }
};

const LOSearchWrapper = styled(posed.div(poseProps))`
  padding: 16px;
  background-color: white;
  height: 100%;
`;

// need to come back and add downshift here

class LOSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  handleLOSelect = officer => {
    console.log(officer);
    // pass upwards to Task
    this.props.onChooseLO(officer);
  };

  render() {
    return (
      <LOSearchWrapper pose={this.state.isVisible ? "visible" : "hidden"}>
        <BackButton />
        <HeaderText
          h1={"Choose a loan officer"}
          subtext={
            "Whoever you select will receive an email letting them know you sent them a lead."
          }
        />
        <Input
          name="search"
          labelText={"Search"}
          placeholderText={"Search loan officers"}
        />
        <div className="recents">
          <h5>Frequently work with:</h5>
          <LORow
            firstName="Anthony"
            lastName="Porter"
            lender={this.props.lender}
            onLOSelect={this.handleLOSelect}
            imageSource={Tones}
          />
          <LORow
            firstName="Leo"
            lastName="Lender"
            lender={this.props.lender}
            onLOSelect={this.handleLOSelect}
            imageSource={Lawder}
          />
        </div>
      </LOSearchWrapper>
    );
  }
}

LOSearch.propTypes = {
  onChooseLO: PropTypes.func.isRequired,
  lender: PropTypes.string.isRequired
};

export default withRouter(LOSearch);
