import React, { Component } from "react";
import "../App.css";
import Task from "./Pages/Task";

class App extends Component {
  constructor() {
    super();

    this.state = {
      buyers: []
    };
  }
  // hmm need a bunch of handlers to capture state? look at original to do example
  // mighttttt warrant learning redux

  render() {
    return (
      <div className="App">
        <Task />
      </div>
    );
  }
}

// where to put state? should keep track of list of buyers

// when you add lead, you just created a new buyer
// push into array of buyers
// on next screen, when they choose LO, add that the to most recent buyer
// on the next screen, pull loan officer info and buyer info from state
// on home screen, show any buyers

// onClick, setState LO info
// then advance to next screen
// so LO should be own component; and exported as withRouter()...

// will this allow them to create infinite leads? I think so
// can fastforward button do the same thing every time? will have to think

export default App;
