import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

import AdvanceButton from "../UI/AdvanceButton";
import LeadRow from "../UI/LeadRow";
import Illo from "../../Assets/no-buyers.svg";

const PipelineHeader = styled.div`
  height: 116px;
  width: 100%;
  background-color: #143859;
  padding: 40px 16px 0 16px;
`;

const BuyerHeader = styled.h1`
  color: white;
`;

const PipelineList = styled.ul`
  margin: 0;
  padding: 0 16px;
`;

const PipelineFooter = styled.div`
  position: relative;
  bottom: 0px;
  height: 56px;
  width: 100%;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.06);
  // background-color: grey;
  text-align: center;
`;

const IlloWrapper = styled.img`
  margin-bottom: 40px;
`;

// function to select tab lol
// something about toggling classNames to create active and inactive version of tabs themselves
// and then display none ? for the inactive content, or something

class Pipeline extends React.Component {
  constructor() {
    super();
  }

  render() {
    let arr = this.props.state;
    const listItems = arr.map(client => (
      <LeadRow client={client} chooseBuyer={this.props.onChooseBuyer} />
    ));

    return (
      <div className="pipeline">
        <PipelineHeader>
          <BuyerHeader>Buyers</BuyerHeader>
          <div className="tab-bar">
            <button className="tab-button active">Leads</button>
            <button className="tab-button inactive">Active</button>
          </div>
        </PipelineHeader>
        <div>
          {this.props.state.length < 1 && (
            <div className="empty-state">
              <IlloWrapper src={Illo} />
              <h2>You don't have any buyers yet.</h2>
              <p>Add a lead by tapping the button below.</p>
            </div>
          )}
          {this.props.state.length > 0 && (
            <PipelineList className="pipeline-list">{listItems}</PipelineList>
          )}
          {/* <p>{this.props.state.buyerInfo.firstName}</p> */}
        </div>

        <PipelineFooter className="pipeline-footer">
          <AdvanceButton screen={"demo/leadbuyer"} buttonText={"Create lead"} />
        </PipelineFooter>
      </div>
    );
  }
}

Pipeline.propTypes = {
  state: PropTypes.array.isRequired,
  onChooseBuyer: PropTypes.func.isRequired
};

export default Pipeline;
