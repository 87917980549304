import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import posed from "react-pose";

const poseProps = {
  hidden: {
    opacity: 0,
    maxHeight: "0px",
    visible: "hidden"
  },
  visible: {
    opacity: 1,
    maxHeight: "400px",
    transition: {
      maxHeight: { ease: "easeOut", duration: 1000 },
      default: { ease: "easeOut", duration: 400, delay: 200 }
    }
  }
};

const ApplicationDetailsWrapper = styled(posed.div(poseProps))`
  padding-top: 16px;
`;

const DetailsFirstRow = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const Detail = styled.div`
  flex-grow: 1;
`;

class ApplicationDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  render() {
    return (
      <ApplicationDetailsWrapper
        className="application-details"
        pose={this.state.isVisible ? "visible" : "hidden"}
      >
        <DetailsFirstRow>
          <Detail>
            <h5>Purchase Price</h5>
            <h4>$500,000</h4>
          </Detail>
          <Detail>
            <h5>Down Payment</h5>
            <h4>$100,000</h4>
          </Detail>
        </DetailsFirstRow>
        <div>
          <h5>County</h5>
          <h4>San Francisco County - CA</h4>
        </div>
        <hr />
      </ApplicationDetailsWrapper>
    );
  }
}

export default ApplicationDetails;
