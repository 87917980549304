import React from "react";
import styled from "styled-components";

import AdvanceButton from "../UI/AdvanceButton";
import Background from "../../Assets/background-pattern.png";
import Wordmark from "../../Assets/BA-wordmark-white.svg";

// hmm only the demo should have the max-width, not the landing
// so maybe we create separate demo wrapper component, which wraps every demo screen
// or apply className="demo" to every demo wrapper, but that seems ineffiicent

const LandingWrapper = styled.div`
  padding: 80px 32px 56px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${Background});
  background-size: cover;
`;

const BA = styled.img`
  width: 40%;
`;

const LandingH2 = styled.h1`
  margin-bottom: 40px;
  color: white;
`;

const Home = () => (
  <LandingWrapper className="home-wrapper">
    {/* <h3>Blend Agent</h3> */}
    <BA src={Wordmark} />
    <div>
      <LandingH2>Manage your buyers from start to close.</LandingH2>
      <AdvanceButton screen={"demo/pipeline"} buttonText={"Get started"} />
    </div>
  </LandingWrapper>
);

export default Home;
