import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

import BackButton from "../UI/BackButton";
import StatusRow from "../UI/StatusRow";
import FFButton from "../UI/FFButton";
import ApplicationDetails from "../UI/ApplicationDetails";
import PATip from "../UI/PATip";
import PAScreen from "../UI/PAScreen";

import Tones from "../../Assets/tones.png";
import Emblem from "../../Assets/emblem-logo.png";
import Wells from "../../Assets/wells-logo.png";

const BuyerActivityHeader = styled.div`
  padding: 0 16px;
  background-color: white;
  display: flex;
  align-items: center;
  height: 76px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const BuyerName = styled.h3`
  flex-grow: 2;
`;

const StatusBar = styled.div`
  width: calc(100% - 32px);
  margin: 16px;
  padding: 12px 16px;
  background-color: white;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  padding: 16px;
  margin-bottom: 8px;
`;

const StatusList = styled.ul`
  margin: 0;
  padding: 0;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LOPic = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
`;

const LOInfo = styled.div`
  flex-grow: 1;
`;

const ContactButton = styled.button`
  flex-shrink: 0;
  color: #66c3cc;

  padding: 12px 16px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  border: solid 1px #eaedf0;
`;

const EmblemLogo = styled.img`
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 8px;
`;

let selectedBuyer = {
  buyerInfo: {
    firstName: "Bailey",
    lastName: "Buyer"
  },
  lead: true,
  leadStatus: "Waiting on Bailey to start their mortgage application.",
  lenderInfo: {
    firstName: "Leo",
    lastName: "Lender",
    lender: "Emblem Bank"
  },
  statuses: [{ statustext: "You invited Bailey to fill out the app" }]
};
let listItems = [];

// followUpsText = "No outstanding follow-ups";

class BuyerActivity extends React.Component {
  constructor() {
    super();
    this.state = {
      statusList: selectedBuyer.statuses,
      FFTimesPressed: 0,
      appDetailsVisible: false,
      PAScreenVisible: false
    };
  }

  componentWillMount() {
    // overwrite defaults if state
    if (this.props.state) {
      this.setState({
        statusList: this.props.state.statuses
      });
      selectedBuyer = this.props.state;
      selectedBuyer.lenderInfo.lender = this.props.lender;
      console.log(selectedBuyer.lenderInfo.lender);
    }
  }

  // listen to timesPressed
  updateTimesPressed = timesPressed => {
    // callback to then trigger logic
    this.setState({ FFTimesPressed: timesPressed }, () => {
      this.checkTimesPressed(timesPressed);
    });
  };

  checkTimesPressed = timesPressed => {
    console.log("checking times pressed: " + this.state.FFTimesPressed);
    if (this.state.FFTimesPressed === 1) {
      let status3 = {
        statusID: 3,
        type: "status",
        statusText: `${
          selectedBuyer.buyerInfo.firstName
        } submitted their application`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status3]
      });
    } else if (this.state.timesPressed === 2) {
      console.log("follow ups");
    } else if (this.state.FFTimesPressed === 3) {
      let status4 = {
        statusID: 4,
        type: "status",
        statusText: `${
          selectedBuyer.buyerInfo.firstName
        } has been pre-approved`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status4]
      });
    } else if (this.state.FFTimesPressed === 4) {
      let status5 = {
        statusID: 5,
        type: "status",
        statusText: `${
          selectedBuyer.buyerInfo.firstName
        } has entered into contract`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status5]
      });
    } else if (this.state.FFTimesPressed === 5) {
      let status6 = {
        statusID: 6,
        type: "status",
        statusText: `Title has been ordered`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status6]
      });
    } else if (this.state.FFTimesPressed === 6) {
      let status7 = {
        statusID: 7,
        type: "status",
        statusText: `Appraisal has been ordered`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status7]
      });
    } else if (this.state.FFTimesPressed === 7) {
      let status8 = {
        statusID: 8,
        type: "status",
        statusText: `Title received`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status8]
      });
    } else if (this.state.FFTimesPressed === 8) {
      let status9 = {
        statusID: 9,
        type: "status",
        statusText: `Appraisal received: approved`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status9]
      });
    } else if (this.state.FFTimesPressed === 9) {
      let status10 = {
        statusID: 10,
        type: "status",
        statusText: `${
          selectedBuyer.buyerInfo.firstName
        } has been conditionally approved`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status10]
      });
    } else if (this.state.FFTimesPressed === 10) {
      let status11 = {
        statusID: 11,
        type: "status",
        statusText: `${
          selectedBuyer.buyerInfo.firstName
        }'s loan has been sent to underwriting`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status11]
      });
    } else if (this.state.FFTimesPressed === 11) {
      let status12 = {
        statusID: 12,
        type: "status",
        statusText: `${selectedBuyer.buyerInfo.firstName} is clear to close`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status12]
      });
    } else if (this.state.FFTimesPressed === 12) {
      let status13 = {
        statusID: 13,
        type: "status",
        statusText: `${selectedBuyer.buyerInfo.firstName}'s loan has funded!`,
        statusTime: "Just now"
      };
      this.setState({
        statusList: [...this.state.statusList, status13]
      });
    }
  };

  toggleAppDetails = () => {
    console.log("toggling");
    this.setState({ appDetailsVisible: !this.state.appDetailsVisible });
  };

  togglePAVisible = () => {
    console.log(this.state.FFTimesPressed);
    this.setState({
      // FFTimesPressed: this.state.FFTimesPressed,
      PAScreenVisible: !this.state.PAScreenVisible
    });
  };

  render() {
    // sort activity feed
    listItems = this.state.statusList
      .sort((a, b) =>
        a.statusID < b.statusID ? 1 : b.statusID < a.statusID ? -1 : 0
      )
      .map(status => <StatusRow key={status.statusID} status={status} />);

    return (
      <div className="buyer-activity">
        {this.state.PAScreenVisible && (
          <PAScreen onClose={this.togglePAVisible} lender={this.props.lender} />
        )}
        {!this.state.PAScreenVisible && (
          <div className="buyer-activity-wrapper">
            <BuyerActivityHeader className="buyer-activity-header">
              <BackButton />
              <BuyerName>
                {selectedBuyer.buyerInfo.firstName +
                  " " +
                  selectedBuyer.buyerInfo.lastName}
              </BuyerName>
              <p> </p>
            </BuyerActivityHeader>
            {this.state.FFTimesPressed == 3 && (
              <PATip
                LOName={selectedBuyer.lenderInfo.firstName}
                onView={this.togglePAVisible}
              />
            )}
            <StatusBar>
              <div className="icon-and-word">
                <MaterialIcon icon="link" color="#39BF7C" />
                <p className="connected-word">Connnected</p>
              </div>
              <a>Learn more</a>
            </StatusBar>
            <Card>
              <h2>Activity</h2>
              <hr />

              {this.props.state && <StatusList>{listItems}</StatusList>}
              {/* <a>Show more</a> */}
            </Card>
            <Card classNam="buyer-card">
              <h2>Buyer</h2>
              <CardWrapper>
                <h3>
                  {selectedBuyer.buyerInfo.firstName +
                    " " +
                    selectedBuyer.buyerInfo.lastName}
                </h3>
                <ContactButton>Contact</ContactButton>
              </CardWrapper>

              {this.state.FFTimesPressed > 0 && (
                <div className="application-status-wrapper">
                  <hr />
                  <div className="application-status">
                    <MaterialIcon icon="description" color="#8A9CAC" />
                    <div>
                      <h4>Application submitted</h4>
                      {this.state.FFTimesPressed == 2 ? (
                        <p className="outstanding-fu">
                          3 outstanding follow-up tasks
                        </p>
                      ) : (
                        <p>No oustanding follow-up tasks</p>
                      )}
                    </div>
                  </div>
                  <hr />
                  {this.state.appDetailsVisible && <ApplicationDetails />}
                  {/* <ApplicationDetails
                    isVisible={this.state.appDetailsVisible}
                  /> */}

                  <button
                    className="link-button"
                    onClick={this.toggleAppDetails}
                  >
                    {this.state.appDetailsVisible
                      ? "Hide application details"
                      : "Show application details"}
                  </button>
                </div>
              )}
            </Card>
            <Card>
              <h2>Lender</h2>

              <CardWrapper>
                <LOPic src={Tones} />
                <LOInfo>
                  <h3>
                    {selectedBuyer.lenderInfo.firstName +
                      " " +
                      selectedBuyer.lenderInfo.lastName}
                  </h3>
                  <p>{selectedBuyer.lenderInfo.lender}</p>
                </LOInfo>
                <ContactButton>Contact</ContactButton>
              </CardWrapper>
              <EmblemLogo
                src={this.props.lender == "Wells Fargo" ? Wells : Emblem}
              />
            </Card>
            {/* {!this.state.PAScreenVisible && (
          <FFButton onPress={this.updateTimesPressed} />
        )} */}
          </div>
        )}
        <FFButton
          visible={!this.state.PAScreenVisible}
          onPress={this.updateTimesPressed}
        />
      </div>
    );
  }
}

BuyerActivity.propTypes = {
  state: PropTypes.object,
  lender: PropTypes.string.isRequired
};

export default BuyerActivity;
