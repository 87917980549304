import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

import Illo from "../../Assets/illo-agent-phone.svg";
import AppStore from "../../Assets/app-store-badge.svg";
import PlayStore2 from "../../Assets/google-play-2.png";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  text-align: center;
`;

const ModalWrapper = styled.div`
  background-color: white;
  display: inline-block;
  width: 327px;
  padding: 16px 8px;
  margin-top: 40%;
  border-radius: 8px;
  z-index: 3;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
`;

const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 8px;
`;

const IlloWrapper = styled.img`
  margin-bottom: 40px;
  width: 124px;
  heigth: 124px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Badge = styled.img`
  height: 44px;
`;

class Modal extends React.Component {
  constructor() {
    super();
  }

  closeModal = () => {
    console.log("close out");
    this.props.handleClose();
  };

  render() {
    return (
      <Overlay>
        <ModalWrapper>
          <CloseWrapper>
            <MaterialIcon
              icon="close"
              color="#72889B"
              size={20}
              onClick={this.closeModal}
            />
          </CloseWrapper>
          <IlloWrapper src={Illo} />

          <h1>Download free</h1>
          <BadgeWrapper>
            <a href="https://itunes.apple.com/us/app/id1460586793">
              <Badge src={AppStore} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.blend.realtor">
              <Badge src={PlayStore2} />
            </a>
          </BadgeWrapper>
        </ModalWrapper>
      </Overlay>
    );
  }
}

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default Modal;
