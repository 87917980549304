import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import posed from "react-pose";

const poseProps = {
  hidden: {
    opacity: 0,
    maxHeight: "0px"
  },
  visible: {
    opacity: 1,
    maxHeight: "200px",
    transition: {
      maxHeight: { ease: "easeOut", duration: 1000 },
      default: { ease: "easeOut", duration: 400, delay: 200 }
    }
  }
};

const LeadRowWrapper = styled(posed.div(poseProps))`
  padding: 16px 0;
  // display: flex;
  align-items: center;
  border-bottom: solid 1px #eaedf0;
`;

const LeadText = styled.h3`
  margin-bottom: 4px;
`;

class LeadRow extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  chooseBuyer = () => {
    console.log("clicked lead");
    let buyer = this.props.client;
    // hmmm, I need to pass the client info
    this.props.chooseBuyer(buyer);
    // redirect
    this.props.history.push(`/demo/buyeractivity`);
  };

  render() {
    return (
      <LeadRowWrapper
        onClick={this.chooseBuyer}
        pose={this.state.isVisible ? "visible" : "hidden"}
      >
        <div>
          <LeadText>
            {this.props.client.buyerInfo.firstName +
              " " +
              this.props.client.buyerInfo.lastName}
          </LeadText>
          <p>{this.props.client.leadStatus}</p>
        </div>
      </LeadRowWrapper>
    );
  }
}

LeadRow.propTypes = {
  client: PropTypes.object.isRequired,
  chooseBuyer: PropTypes.func.isRequired
};

export default withRouter(LeadRow);
