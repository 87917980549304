import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import Tones from "../../Assets/tones.png";

const LoanOfficer = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eaedf0;
`;

const LOPic = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 16px;
`;

class LORow extends React.Component {
  constructor() {
    super();
  }

  chooseLO = () => {
    let email = this.props.firstName.toLowerCase() + "@wellsfargo.com";
    let officer = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      lender: "Emblem Bank",
      phone: "(800) - 555 - 5555",
      email: email
    };
    // handler function
    this.props.onLOSelect(officer);
    // move to review screen
    this.props.history.push(`/demo/review`);
  };

  render() {
    return (
      <LoanOfficer className={"loan-officer-row"} onClick={this.chooseLO}>
        <LOPic src={this.props.imageSource} />
        <div className="loan-officer-row">
          <h3>{this.props.firstName + " " + this.props.lastName} </h3>
          <p>{this.props.lender}</p>
        </div>
      </LoanOfficer>
    );
  }
}

LORow.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  lender: PropTypes.string.isRequired,
  onLOSelect: PropTypes.func.isRequired,
  imageSource: PropTypes.string.isRequired
};

export default withRouter(LORow);
