import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BackButton from "../UI/BackButton";
import HeaderText from "../UI/HeaderText";
import AdvanceButton from "../UI/AdvanceButton";
import posed from "react-pose";

import Emblem from "../../Assets/emblem-logo.png";
import Wells from "../../Assets/wells-logo.png";
// wells

const poseProps = {
  hidden: {
    opacity: 0,
    x: 40
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      opacity: { ease: "easeOut", duration: 500 },
      x: { ease: "easeOut", duration: 300, delay: 50 }
    }
  }
};

const LeadReviewWrapper = styled(posed.div(poseProps))`
  padding: 16px;
  background-color: white;
  height: 100%;
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const EmblemLogo = styled.img`
  display: block;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
`;

class LeadReview extends React.Component {
  constructor() {
    super();
    this.state = {
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  createLead = () => {
    console.log("finalize this lead");
    console.log(this.props.ingestLead);
    // push to task level
    // then pass to pipeline as a prop ?

    // or pass to App, which has all of our buyers in one place
    this.props.bubbleLead(this.props.ingestLead);
  };

  render() {
    return (
      <LeadReviewWrapper
        className="lead-screen-3"
        pose={this.state.isVisible ? "visible" : "hidden"}
      >
        <BackButton />
        <HeaderText
          h1={"Review and invite lead"}
          subtext={`${
            this.props.ingestLead.buyer.firstName
          } will get an email with ${
            this.props.ingestLead.loanOfficer.firstName
          }'s application link.`}
        />
        <div className="review-card review-lender">
          <h5>Lender</h5>
          <h3>
            {this.props.ingestLead.loanOfficer.firstName +
              " " +
              this.props.ingestLead.loanOfficer.lastName}
          </h3>
          <ContactWrapper>
            <p className="review-phone">
              {this.props.ingestLead.loanOfficer.phone}
            </p>
            <div className="lil-dot" />
            <p>{this.props.ingestLead.loanOfficer.email}</p>
          </ContactWrapper>
          <EmblemLogo
            src={this.props.lender == "Wells Fargo" ? Wells : Emblem}
          />
        </div>
        <div className="review-card review-buyer">
          <h5>Buyer</h5>
          <h3>
            {this.props.ingestLead.buyer.firstName +
              " " +
              this.props.ingestLead.buyer.lastName}
          </h3>
          <ContactWrapper>
            <p className="review-phone">{this.props.ingestLead.buyer.phone}</p>
            <div className="lil-dot" />
            <p>{this.props.ingestLead.buyer.email}</p>
          </ContactWrapper>
        </div>

        <AdvanceButton
          className="advance"
          screen={"demo/pipeline"}
          buttonText={"Create lead"}
          type="submit"
          submissionFunction={this.createLead}
        />
      </LeadReviewWrapper>
    );
  }
}

LeadReview.propTypes = {
  ingestLead: PropTypes.object.isRequired,
  bubbleLead: PropTypes.func.isRequired,
  lender: PropTypes.string.isRequired
};

export default LeadReview;
