import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const HeaderTextWrapper = styled.div`
  margin-bottom: 32px;
`;

const StyledSubtext = styled.p`
  font-size: 16px;
  color: #72889b;
  margin-top: 8px;
  line-height: 1.5;
`;

const HeaderText = props => (
  <HeaderTextWrapper>
    <h1>{props.h1}</h1>
    <StyledSubtext>{props.subtext}</StyledSubtext>
  </HeaderTextWrapper>
);

HeaderText.propTypes = {
  h1: PropTypes.string.isRequired,
  subtext: PropTypes.string
};

export default HeaderText;
