import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import posed from "react-pose";

import HeaderText from "../UI/HeaderText";
import Input from "../UI/Input";
import AdvanceButton from "../UI/AdvanceButton";
import BackButton from "../UI/BackButton";

const poseProps = {
  hidden: {
    opacity: 0,
    y: 10
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      opacity: { ease: "easeOut", duration: 500 },
      y: { ease: "easeOut", duration: 200, delay: 50 }
    }
  }
};

const LeadWrapper = styled(posed.div(poseProps))`
  padding: 16px;
  background-color: white;
  height: 100%;
`;

class LeadBuyer extends React.Component {
  constructor() {
    super();
    // shouldn't have state here ?
    this.state = {
      firstName: "Bailey",
      lastName: "Buyer",
      phone: "(800) - 123 - 4567",
      email: "baileybuyer@gmail.com",
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  handleChange = (inputName, value) => {
    this.setState({
      [inputName]: value
    });
  };

  submitForm = () => {
    let buyer = this.state;
    console.log(buyer);
    this.props.onLeadSubmit(buyer);
  };

  render() {
    return (
      <LeadWrapper
        className="lead-screen-1"
        pose={this.state.isVisible ? "visible" : "hidden"}
      >
        <BackButton />
        <HeaderText
          h1={"Add a lead"}
          subtext={"Enter the info for this potential buyer."}
        />
        <form>
          <div className="lead-first-inputs">
            <Input
              // value should be whatever this.state is, so when they go back the values are still there
              // but then i can't set defaults in state; so maybe i add defaults at very end
              className="first-input"
              name="firstName"
              labelText="First name"
              placeholderText={"Bailey"}
              onInputChange={this.handleChange}
            />
            <Input
              name="lastName"
              labelText="Last name"
              placeholderText={"Buyer"}
              onInputChange={this.handleChange}
            />
          </div>
          <Input
            name="phone"
            labelText="Phone number"
            placeholderText={"(123) - 456 - 7890"}
            onInputChange={this.handleChange}
          />
          <Input
            name="email"
            labelText="Email"
            placeholderText={"baileybuyer@gmail.com"}
            onInputChange={this.handleChange}
          />
          <AdvanceButton
            screen={"demo/losearch"}
            buttonText={"Next"}
            type="submit"
            submissionFunction={this.submitForm}
          />
        </form>
      </LeadWrapper>
    );
  }
}

LeadBuyer.propTypes = {
  // to send state up to Task.js
  onLeadSubmit: PropTypes.func.isRequired
};

export default LeadBuyer;
