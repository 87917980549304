import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

class AdvanceButton extends Component {
  constructor() {
    super();
    // create a ref to handle disabled state
    this.AdvanceRef = React.createRef();
  }

  handleClick = () => {
    // advance to the next screen
    console.log("we're moving to " + this.props.screen);
    this.props.history.push(`/${this.props.screen}`);
    // use submit logic
    if (this.props.type == "submit") {
      this.props.submissionFunction();
    }
  };

  render() {
    return (
      <button
        className={this.props.className ? this.props.className : "advance"}
        ref={this.AdvanceRef}
        type={this.props.type}
        disabled={this.props.disabled}
        onClick={this.handleClick}
      >
        {this.props.buttonText}
      </button>
    );
  }
}

// proptypes
AdvanceButton.propTypes = {
  screen: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  submissionFunction: PropTypes.func, // if we want to add logic to onClick, most commonly for submitting
  className: PropTypes.string
};

export default withRouter(AdvanceButton);
