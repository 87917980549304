import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

const FFButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 16px 16px;
  border-radius: 100px;
  background-color: #2f2f2f;
  position: fixed;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.35);
  left: 50%;
  margin-left: -73.5px;
`;

class FFButton extends React.Component {
  constructor() {
    super();
    this.state = {
      timesPressed: 0
    };
  }

  fastForward = () => {
    // callback to wait for state to be updated
    this.setState({ timesPressed: this.state.timesPressed + 1 }, () => {
      this.props.onPress(this.state.timesPressed);
    });
  };

  render() {
    return (
      <FFButtonWrapper
        className={this.props.visible ? "FF-visible" : "FF-invisible"}
        onClick={this.fastForward}
      >
        <MaterialIcon icon="fast_forward" color="white" />
        Fast forward
      </FFButtonWrapper>
    );
  }
}

FFButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired
};

// might need to export withRouter, so that we can "advance" to notifications screen
export default FFButton;
