import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #5b748b;
  margin-bottom: 8px;
`;

class Input extends React.Component {
  constructor() {
    super();
  }

  handleChange = e => {
    // pass the value upwards; will be used for state
    let name = this.props.name;
    if (this.props.onInputChange) {
      this.props.onInputChange(name, e.target.value);
    }
  };

  render() {
    return (
      <InputWrapper className={this.props.className}>
        <StyledLabel>{this.props.labelText}</StyledLabel>
        <input
          name={this.props.name}
          type="text"
          placeholder={this.props.placeholderText}
          onChange={this.handleChange}
        />
      </InputWrapper>
    );
  }
}

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  type: PropTypes.string,
  onInputChange: PropTypes.func
};

export default Input;
