import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";

import posed from "react-pose";

const poseProps = {
  hidden: {
    opacity: 0,
    maxHeight: "0px"
  },
  visible: {
    opacity: 1,
    maxHeight: "200px",
    transition: {
      maxHeight: { ease: "easeOut", duration: 1000 },
      default: { ease: "easeOut", duration: 400, delay: 200 }
    }
  }
};

const StatusRowWrapper = styled(posed.div(poseProps))`
  padding: 16px 0;
  display: flex;
  border-bottom: solid 1px #f1f3f5;
`;

// const StatusRowWrapper = styled.div`
//   padding: 16px 0;
//   display: flex;
//   border-bottom: solid 1px #f1f3f5;
// `;

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  background-color: #e9f8f1;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class StatusRow extends React.Component {
  constructor() {
    super();
    this.state = {
      className: "status-row",
      isVisible: false
    };
  }

  componentWillMount() {
    // this.setState({ className: "status-row" });
  }
  componentDidMount() {
    this.setState({ isVisible: true });
    // this.setState({ className: "status-row status-row-visible" });
  }

  clickedStatus = () => {
    console.log("clicked on a status");
  };

  render() {
    return (
      <StatusRowWrapper
        className={this.state.className}
        pose={this.state.isVisible ? "visible" : "hidden"}
        onClick={this.clickedStatus}
      >
        <IconWrapper className="status-icon">
          <MaterialIcon icon="done" color="#39BF7C" />
        </IconWrapper>
        <div className="status-text">
          <h4>{this.props.status.statusText}</h4>
          <h5>{this.props.status.statusTime}</h5>
        </div>
      </StatusRowWrapper>
    );
  }
}

StatusRow.propTypes = {
  // type: PropTypes.string.isRequired,
  status: PropTypes.object
};

export default StatusRow;
