import React from "react";
import BackButton from "../UI/BackButton";

class PreApproval extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <BackButton />
        <h2>pre approval</h2>
      </div>
    );
  }
}

export default PreApproval;
