import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AdvanceButton from "../UI/AdvanceButton";
import Modal from "../UI/Modal";

import BA from "../../Assets/BA-wordmark-white.svg";
import BACentered from "../../Assets/BA-wordmark-white-centered.svg";
import Pixel from "../../Assets/pixel-screen-white.png";

/***** landing page *****/
const LandingHeader = styled.div`
  position: fixed;
  background-color: #143859;
  width: 100%;
  height: 75px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  @media (min-width: 500px) {
    padding: 16px 24px;
  }
`;

const BASmall = styled.img`
  width: 60px;
`;

const BALarge = styled.img`
  width: 40%;
  max-width: 160px;
`;

const LandingContent = styled.div`
  padding: 144px 16px 48px 16px;
  text-align: center;
`;

const Introducing = styled.h5`
  margin-bottom: 16px;
  letter-spacing: 2px;
`;

const Tagline = styled.p`
  color: white;
  opacity: 0.7;
  font-size: 16px;
  line-height: 1.5;
`;

const Device = styled.img`
  width: 100%;
  max-width: 400px;
  margin-top: 40px;
`;

class Landing extends React.Component {
  constructor() {
    super();
    this.state = {
      modalVisible: false
    };
  }

  componentWillMount() {
    this.props.handlePhoneWrapper(true);
  }

  componentWillUnmount() {
    this.props.handlePhoneWrapper(false);
  }

  viewModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    return (
      <div className="landing-background">
        <LandingHeader>
          <BASmall src={BA} />
          <div>
            <AdvanceButton
              className={"demo-button"}
              screen={"demo/home"}
              buttonText={"Demo"}
            />
            <button onClick={this.viewModal}>Download</button>
          </div>
        </LandingHeader>
        {this.state.modalVisible && <Modal handleClose={this.closeModal} />}

        <LandingContent>
          <Introducing>Introducing</Introducing>
          <BALarge src={BACentered} />
          <h2>Blend Agent</h2>

          <Tagline>
            The first app for real estate agents to track their buyers along
            their loan journey, from application through close.
          </Tagline>
          <Device src={Pixel} />
        </LandingContent>
      </div>
    );
  }
}

Landing.propTypes = {
  handlePhoneWrapper: PropTypes.func.isRequired
};

export default Landing;
