import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AdvanceButton from "./AdvanceButton";

const PATipWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  background-color: #143859;
`;

const PATipText = styled.p`
  color: white;
  margin-right: 16px;
`;

class PATip extends React.Component {
  constructor() {
    super();
  }

  viewPA = () => {
    console.log("clicked view");
    this.props.onView();
  };

  render() {
    return (
      <PATipWrapper className="PA-tip">
        <PATipText>
          {this.props.LOName} sent you a pre-approval letter for up to $500,000.
        </PATipText>
        <button onClick={this.viewPA}>View</button>
      </PATipWrapper>
    );
  }
}

PATip.propTypes = {
  LOName: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired
};

export default PATip;
